<template>
  <div class="row mt-2">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Show&nbsp;
                  <b-form-select
                    v-model="perPage"
                    size="sm"
                    :options="pageOptions"
                  ></b-form-select
                  >&nbsp;entries
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div
                id="tickets-table_filter"
                class="dataTables_filter text-md-right"
              >
                <label class="d-inline-flex align-items-center">
                  Search:
                  <b-form-input
                    v-model="filter"
                    type="search"
                    placeholder="Search..."
                    class="form-control form-control-sm ml-2"
                  ></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive mb-0">
            <b-table
              :items="workstationData"
              :fields="fields"
              responsive="sm"
              :busy="isBusy"
              :per-page="perPage"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
              @row-clicked="onRow"
            >
              <template #table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #cell(actions)="row">
                <button class="action-btn" @click="deleteWorkStation(row.item)">
                  <i class="fas fa-trash-alt"></i>
                </button>
                <button class="action-btn" @click="editWorkStation(row.item)">
                  <i class="fas fa-edit"></i>
                </button>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div
                class="dataTables_paginate paging_simple_numbers float-right"
              >
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @change="updatePage"
                  ></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WorktimeModal
      v-on:resetModal="selectedWorkStation = null"
      v-if="selectedWorkStation"
      :selectedWorkStation="selectedWorkStation"
    />
  </div>
</template>

<script>
import { workstations } from "@/config/api/workstations";
import WorktimeModal from "@/components/workstations/worktime-modal";

export default {
  components: { WorktimeModal },

  props: ["reload"],
  data() {
    return {
      workstationData: [],
      selectedWorkStation: null,

      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "name", sortable: false },
        { key: "city", sortable: false },
        { key: "actions", sortable: false },
      ],
    };
  },
  created() {
    this.getAllWorkstation();
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  watch: {
    reload(val) {
      if (val) {
        this.getAllWorkstation();
      }
    },
  },

  methods: {
    updatePage(page) {
      console.log("Test");
      this.getAllWorkstation(page);
    },
    onRow(record, index) {
      this.$router.push({ path: "workstation", query: { id: record._id } });
      console.log(record._id, index);
    },
    editWorkStation(data) {
      this.selectedWorkStation = data;
      this.showActionModal();
    },
    showActionModal() {
      this.$bvModal.show("worktime-modal");
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllWorkstation(page = 1) {
      this.isBusy = true;
      const api = workstations.get;
      api.params = { page: page };
      this.generateAPI(api)
        .then((res) => {
          this.workstationData = res.data.workstation;
          this.totalRows = res.data.total_no_of_documents;
          console.log(this.totalRows);
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.isBusy = false;
          this.$emit("dataLoaded");
        });
    },
    deleteWorkStation(item) {
      this.$bvModal
        .msgBoxConfirm("Please confirm that you want to delete workstation.", {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = workstations.delete;
            api.id = item._id;
            this.generateAPI(api)
              .then(() => {
                this.$bvToast.toast("Workstation deleted successfully!", {
                  title: "Workstation",
                  variant: "success",
                  solid: true,
                });
                this.getAllWorkstation();
              })
              .catch((err) => {
                this.$bvToast.toast(
                  `Workstation is not deleted,${err.response.data}`,
                  {
                    title: "Workstation",
                    variant: "danger",
                    solid: true,
                  }
                );
              });
          }
        })
        .catch((err) => {
          console.log(err);
          // An error occurred
        });
    },
  },
};
</script>
