<template>
  <b-modal id="workstation-modal" size="xl" hide-footer>
    <FormulateForm
      name="workstaionForm"
      v-model="values"
      @submit="submitHandler"
    >
      <div class="row mb-3">
        <div class="col-md-6">
          <FormulateInput
            type="text"
            name="name"
            label="Name"
            placeholder="Name here please"
            validation="required"
          />
        </div>
        <div class="col-md-6">
          <FormulateInput
            type="text"
            name="city"
            label="City"
            placeholder="Please enter the city name"
            validation="required"
          />
        </div>
      </div>

      <mapView v-on:locationChange="locationChange" />
      <h6 class="mt-4">User Details</h6>
      <div style="margin: 0 auto; width: 30%; text-align: center;">
        <b-button @click="showUserNew"> New User </b-button>
      <b-button style="float: right;" @click="showUserExist"> Existing User </b-button>
      </div>
      <div> </div>
      <div
      v-if="isNewUser"
        style="
          background-color: #fafafa;
          padding: 12px;
          margin-top: 8px;
          border-radius: 12px;
        "
      >
        <FormulateInput
          type="text"
          name="username"
          label="Username"
          placeholder="Please enter username"
          validation="required"
        />
        <FormulateInput
          type="email"
          name="email"
          label="Email"
          placeholder="Please enter email"
          validation="required|email"
        />
        <FormulateInput
          type="password"
          name="password"
          label="Password"
          placeholder="Please enter password"
          validation="required|min:8"
        />
         <FormulateInput
              type="select"
              name="roles"
              :options="roles"
              label="Roles"
              placeholder="Please enter Roles"
              validation="required"
            />
      </div>
      <div
      v-if="isExistingUser"
        style="
          background-color: #fafafa;
          padding: 12px;
          margin-top: 8px;
          border-radius: 12px;
        "
      >
<label for="users" style="margin-right:5px;">All Users</label>
<input name="users" v-model="selectedUser" list="brow">
<datalist id="brow">
      <option v-for="(item, i) in allUsers" :key="i">{{item.label}}</option>

</datalist> 
      </div>

      <FormulateInput type="submit" class="mt-3 float-right">
        <span v-if="sending"> Creating..</span><span v-else>Create</span>
      </FormulateInput>
    </FormulateForm>
  </b-modal>
</template>

<script>
import { workstations } from "@/config/api/workstations";
import mapView from "@/components/maps/mapView";

export default {
  props: ["selectedDriver", "roleData", "userData"],
  components: {
    mapView  },
  data() {
    return {
      selectedUser: "",
      isNewUser: false,
      isExistingUser: false,
      values: {},
      sending: false,
      marker: null,
      options: [{country: 'Canada', code: 'CA'},]
    };
  },
  computed: {
    roles() {
      return this.roleData
        ? this.roleData.map((item) => {
            return {
              label: item.name,
              value: item._id,
            };
          })
        : [];
    },
    allUsers() {
      return this.userData
        ? this.userData.map((item) => {
            return {
              label: item.email
            };
          })
        : [];
    },
  },
  methods: {
    showUserNew() {
      this.isExistingUser = false;
      this.isNewUser = !this.isNewUser;      
    },
    showUserExist() {
      this.isNewUser = false;
      this.isExistingUser = !this.isExistingUser;      
    },
    async submitHandler(data) {
      if(this.isExistingUser)
      {
        data.user_id = this.userData.filter((item) => item.email === this.selectedUser)[0]._id
      }
      else
      {
        data.user =  {
          name: data.username,
          email: data.email,
          password: data.password,
          roles: [data.roles],
        }
      }
      if (this.markers === null) {
        this.$bvToast.toast("Workstation is not added!", {
          title: "Workstation",
          variant: "danger",
          solid: true,
        });
        return;
      }
      const api = workstations.create;
      api.data = {
        ...data,
        cordinates: [this.marker.lat, this.marker.lng],
        
      };
      console.log(api);
      this.sending = true;
      this.generateAPI(api)
        .then(() => {
          this.$formulate.reset("workstaionForm");
          this.$bvToast.toast("Workstation added successfully", {
            title: "Workstation",
            variant: "success",
            solid: true,
          });
          this.$emit("workstationAdded", true);
        })
        .catch(() => {
          this.$bvToast.toast("Workstation is not added", {
            title: "Workstation",
            variant: "danger",
            solid: true,
          });
        })
        .finally(() => {
          this.sending = false;
        });
    },
    update(val) {
      console.log(val);
    },
    locationChange(location) {
      this.marker = location;
    },
  },
  watch: {},
};
</script>
