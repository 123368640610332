export const workstations = {
  get: {
    url: "workstation/get",
    method: "GET",
    params: null,
    id: null,
  },
  create: {
    url: "workstation/create",
    method: "POST",
    data: null,
  },
  update: {
    url: "workstation/update/",
    method: "PUT",
    data: null,
    id: null,
  },
  delete: {
    url: "workstation/delete/",
    method: "DELETE",
    id: null,
  },
  byId: {
    url: "workstation/getWorkStationById/",
    method: "GET",
    params: null,
    id: null,
  },
};
