<template>
  <Layout>
    <b-button @click="openModal"> Add Workstation </b-button>
    <Modal v-on:workstationAdded="workstationAdded"  :roleData="roleData" :userData="userData"/>
    <Table :reload="reload" v-on:dataLoaded="reload = false" />
  </Layout>
</template>

<script>
import { roles } from "@/config/api/roles";
import Modal from "@/components/workstations/action-modal";
import Layout from "@/views/layouts/main";
import Table from "@/components/workstations/table";
import { user } from "@/config/api/users";

export default {
  components: {
    Layout,
    Modal,
    Table,
  },
  data() {
    return {
      userRoles: [],
      userData: [],
      reload: false,
      roleData:[]
    };
  },
  created(){
    this.getAllRole()
    this.getAllUsers()
  },
  methods: {
    getAllUsers() {
      const api = user.get;
      this.generateAPI(api)
        .then((res) => {
          this.userData = res.data.user;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    getAllRoles() {
      const data = roles.get;
      this.generateAPI(data)
        .then((res) => {
          this.userRoles = res.data.roles;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    openModal() {
      this.$bvModal.show("workstation-modal");
    },
    workstationAdded(val) {
      if (val) {
        this.$bvModal.hide("workstation-modal");
        this.reload = true;
      }
    },
    getAllRole() {
      const api = roles.get;
      this.generateAPI(api)
        .then((res) => {
          this.roleData = res.data.roles;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
  },
};
</script>

<style></style>
